<template>
  <div class="playerprofile">
    <template v-if="isMobile(true)">
      <div class="baseInfoMob baseInfo">
        <b-container>
          <b-row style="background-image: linear-gradient(#5897E6 0% , #F5F5F5);">
            <b-col class="playerImg col-md-6 col-12">
              <div class="profileImageOuter">
                <template v-if="playerinfo.IMAGE == 'N'">
                  <div class="profileImage" v-bind:style="{ 'background-image': 'url(' + 'https://assets.asiantour.com/asian-tour/2024/04/User_Gradient_LandscapeBLUE.png' + ')' }">
                  </div> 
                </template>
                <template v-else>
                  <div class="profileImage" v-bind:style="{ 'background-image': 'url(' + DEFIMAGESAMS3 + 'apga' + '/' + 'media/photos/' + refno + '.jpg' + ')' }">
                  </div> 
                </template>             
              </div>
            </b-col>
          </b-row>
          <b-row class="splashInfo">
            <b-col class="basicInfo col-md-6 col-12">
              <b-row>
                <b-col>
                  <p id="name">
                    {{ playerinfo.MEMBER.FULL_NAME }}
                    <span v-if="show_name_pronounce">
                      <b-button @click="namePronounce()" class="namePronounce"
                        ><font-awesome-icon
                          class="icon"
                          :icon="['fa', 'volume-up']"
                      /></b-button>
                    </span>
                  </p>
                </b-col>
                <b-col>
                  <div
                    class="mobileFloat"
                    v-if="playerinfo.MEMBER.SB_SPONSORED === 'Y'"
                  >
                    <b-img
                      class="sponsor"
                      :src="'https://assets.asiantour.com/asian-tour/2024/02/titleist_oom-copy.png'"
                    ></b-img>
                  </div>
                </b-col>
              </b-row>
              <b-row class="topStats">
              </b-row>
              <b-row>
                <b-col>
                  <p>
                    OOM Position
                  </p>
                </b-col>
                <template>
                  <b-col>
                    <p
                      v-if="
                        enhancedPlayerInfo.ooms.data_format[0] === ' ' ||
                          enhancedPlayerInfo.ooms.data_format == '_'
                      "
                      class="infoTopSec"
                    >
                      -
                    </p>
                    <p v-else class="infoTopSec">
                      <span
                        v-if="
                          Array.isArray(enhancedPlayerInfo.ooms.tourn_details)
                        "
                      >
                        <template
                          v-if="
                            enhancedPlayerInfo.ooms.tourn_details &&
                              enhancedPlayerInfo.ooms.tourn_details[2] ==
                                undefined
                          "
                        >
                          {{ enhancedPlayerInfo.ooms.tourn_details[1].pos }}
                        </template>
                        <template v-else>
                          {{ enhancedPlayerInfo.ooms.tourn_details[2].pos }}
                        </template>
                        <!-- {{ enhancedPlayerInfo.ooms.tourn_details[1].pos }} -->
                      </span>
                      <span v-else-if="enhancedPlayerInfo.ooms.length > 0">
                        {{ enhancedPlayerInfo.ooms.tourn_details.pos }}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </p>
                  </b-col>
                </template>
                <b-col>
                    <span class="natBlockmobile">
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            playerinfo.MEMBER.NATIONALITY)
                            | lowercase
                        "
                      />
                    </span>
                </b-col>
              </b-row>
              <template
                v-if="
                  playerinfo.MEMBER.OWGR_RANKING == '0' ||
                    playerinfo.MEMBER.OWGR_RANKING == ''
                "
              ></template>
              <template v-else>
                <b-row>
                  <b-col>
                    <p v-if="playerinfo.MEMBER.OWGR_RANKING.length">
                      OWGR Ranking
                    </p>
                  </b-col>
                  <b-col>
                    <p class="infoTopSec">
                      {{ playerinfo.MEMBER.OWGR_RANKING }}
                    </p>
                  </b-col>
                  <b-col></b-col>
                </b-row>
              </template>
              <b-row class="socialLine">
                <span v-if="playerinfo.MEMBER.FACEBOOK.length">
                <b-nav-item :href="'https://facebook.com/' + playerinfo.MEMBER.FACEBOOK">
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'facebook-square']"
                  />
                </b-nav-item>
                </span>
                <span v-else></span>
                <span v-if="playerinfo.MEMBER.TWITTER.length">
                  <b-nav-item :href="'https://twitter.com/' + playerinfo.MEMBER.TWITTER">
                    <font-awesome-icon
                      class="icon mobMenuFooter"
                      :icon="['fab', 'twitter']"
                    />
                  </b-nav-item>
                </span>
                <span v-else></span>
                <span v-if="playerinfo.MEMBER.INSTAGRAM.length">
                  <b-nav-item :href="'https://instagram.com/' + playerinfo.MEMBER.INSTAGRAM">
                    <font-awesome-icon
                      class="icon mobMenuFooter"
                      :icon="['fab', 'instagram']"
                    />
                  </b-nav-item>
                </span>
                <span v-else></span>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </template>
    <template v-else>
      <div class="baseInfo">
        <b-container>
          <b-row class="splashInfo">
            <b-col class="basicInfo col-md-6 col-12">
              <b-row>
                <b-col>
                  <p id="name">
                    {{ playerinfo.MEMBER.FULL_NAME }}
                    <span v-if="show_name_pronounce">
                      <b-button @click="namePronounce()" class="namePronounce"
                        ><font-awesome-icon
                          class="icon"
                          :icon="['fa', 'volume-up']"
                      /></b-button>
                    </span>
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <template v-if="isMobile(true)">
                  <b-col class="col-md-2">
                    <span class="natBlockmobile newlcass">
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            playerinfo.MEMBER.NATIONALITY)
                            | lowercase
                        "
                      />
                    </span>
                  </b-col>
                </template>
                <template v-else>
                  <b-col class="col-md-2">
                    <img
                      class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + playerinfo.MEMBER.NATIONALITY)
                          | lowercase
                      "
                    />
                  </b-col>
                </template>
                <b-col v-if="playerinfo.MEMBER.SPONSOR.length" class="col-md-2">
                </b-col>
                <b-col v-else class="col-md-2">
                  <div
                    class="mobileFloat"
                    v-if="playerinfo.MEMBER.SB_SPONSORED === 'Y'"
                  >
                    <b-img
                      class="sponsor"
                      :src="'https://assets.asiantour.com/asian-tour/2024/02/titleist_oom-copy.png'"
                    ></b-img>
                  </div>
                </b-col>
              </b-row>
              <b-row class="topStats">
              </b-row>
              <b-row>
                <b-col>
                  <p>
                    OOM Position
                  </p>
                </b-col>
                <template>
                  <b-col>
                    <p
                      v-if="
                        enhancedPlayerInfo.ooms.data_format[0] === ' ' ||
                          enhancedPlayerInfo.ooms.data_format == '_'
                      "
                      class="infoTopSec"
                    >
                      -
                    </p>
                    <p v-else class="infoTopSec">
                      <span
                        v-if="
                          Array.isArray(enhancedPlayerInfo.ooms.tourn_details)
                        "
                      >
                        <template
                          v-if="
                            enhancedPlayerInfo.ooms.tourn_details &&
                              enhancedPlayerInfo.ooms.tourn_details[2] ==
                                undefined
                          "
                        >
                          {{ enhancedPlayerInfo.ooms.tourn_details[1].pos }}
                        </template>
                        <template v-else>
                          {{ enhancedPlayerInfo.ooms.tourn_details[2].pos }}
                        </template>
                        <!-- {{ enhancedPlayerInfo.ooms.tourn_details[1].pos }} -->
                      </span>
                      <span v-else-if="enhancedPlayerInfo.ooms.length > 0">
                        {{ enhancedPlayerInfo.ooms.tourn_details.pos }}
                      </span>
                      <span v-else>
                        -
                      </span>
                    </p>
                  </b-col>
                </template>
              </b-row>
              <template
                v-if="
                  playerinfo.MEMBER.OWGR_RANKING == '0' ||
                    playerinfo.MEMBER.OWGR_RANKING == ''
                "
              ></template>
              <template v-else>
                <b-row>
                  <b-col>
                    <p v-if="playerinfo.MEMBER.OWGR_RANKING.length">
                      OWGR Ranking
                    </p>
                  </b-col>
                  <b-col>
                    <p class="infoTopSec">
                      {{ playerinfo.MEMBER.OWGR_RANKING }}
                    </p>
                  </b-col>
                </b-row>
              </template>
              <b-row class="socialLine">
                <span v-if="playerinfo.MEMBER.FACEBOOK.length">
                <b-nav-item :href="'https://facebook.com/' + playerinfo.MEMBER.FACEBOOK">
                  <font-awesome-icon
                    class="icon mobMenuFooter"
                    :icon="['fab', 'facebook-square']"
                  />
                </b-nav-item>
                </span>
                <span v-else></span>
                <span v-if="playerinfo.MEMBER.TWITTER.length">
                  <b-nav-item :href="'https://twitter.com/' + playerinfo.MEMBER.TWITTER">
                    <font-awesome-icon
                      class="icon mobMenuFooter"
                      :icon="['fab', 'twitter']"
                    />
                  </b-nav-item>
                </span>
                <span v-else></span>
                <span v-if="playerinfo.MEMBER.INSTAGRAM.length">
                  <b-nav-item :href="'https://instagram.com/' + playerinfo.MEMBER.INSTAGRAM">
                    <font-awesome-icon
                      class="icon mobMenuFooter"
                      :icon="['fab', 'instagram']"
                    />
                  </b-nav-item>
                </span>
                <span v-else></span>
              </b-row>
            </b-col>
            <b-col class="playerImg col-md-6 col-12">
              <div class="profileImageOuter">
                <template v-if="playerinfo.IMAGE == 'N'">
                  <div class="profileImage" v-bind:style="{ 'background-image': 'url(' + 'https://assets.asiantour.com/asian-tour/2024/04/User_Gradient_LandscapeBLUE.png' + ')' }">
                  </div> 
                </template>
                <template v-else>
                  <div class="profileImage" v-bind:style="{ 'background-image': 'url(' + DEFIMAGESAMS3 + 'apga' + '/' + 'media/photos/' + refno + '.jpg' + ')' }">
                  </div> 
                </template>             
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </template>

    <!-- Bio Section -->

    <template v-if="playerinfo.BIOGRAPHY.length">
      <div class="bioGrey">
        <b-container>
          <b-row>
            <b-col>
              <h4 class="bioTitle">
                Biography
              </h4>
              <p v-if="playerinfo.BIOGRAPHY.length" class="bio">
                {{ playerinfo.BIOGRAPHY }}
              </p>
              <p v-else class="bio">
                No Information
              </p>
              <p v-if="playerinfo.BIOGRAPHY2.length" class="bio">
                {{ playerinfo.BIOGRAPHY2 }}
              </p>
              <p v-if="playerinfo.BIOGRAPHY3.length" class="bio">
                {{ playerinfo.BIOGRAPHY3 }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </template>

  
    <!-- Middle Stats -->
    <templates v-if="this.StatData !== undefined">
      <b-row class="container MiddleStat" :class="{extraPad : !playerinfo.BIOGRAPHY.lengt}">
        <b-col cols lg="8" sm=12>
          <template v-if="isMobile(true)">
            <b-row>
              <b-col class="bigPrizeMoney">
                <template v-if="filterBSPM.length">
                  {{ filterBSPM[0].VALUE }}
                </template>
                <template v-else>
                  -
                </template>
                <span class="bspm-text">
                  <div class="BSPMblock"></div>
                  <span class="bestSingleMoney">Best Single Prize Money</span>
                </span>
              </b-col>
            </b-row>
            <b-row class="mobMiddleStats">
              <b-col>
                <b-row>
                  <b-col class="greyText">
                    <div class="TPblock"></div>
                    Tournament Played
                  </b-col>
                  <b-col>
                    <div class="TP" v-if="filterTW.length">
                      {{ filterTW[0].PLAYED }}
                    </div>
                    <div class="TP" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="greyText">
                    <div class="TWblock"></div>
                    Tournaments Wins
                  </b-col>
                  <b-col>
                    <div class="TW" v-if="filterTW.length">
                      {{ filterTW[0].VALUE }}
                    </div>
                    <div class="TW" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="greyText">
                    <div class="TTblock"></div>
                    Top Ten Finishes
                  </b-col>
                  <b-col>
                    <div class="TT" v-if="filterTTF.length">
                      {{ filterTTF[0].VALUE }}
                    </div>
                    <div class="TT" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row>
              <b-col class="bigPrizeMoney">
                <template v-if="filterBSPM.length">
                  {{ filterBSPM[0].VALUE }}
                </template>
                <template v-else>
                  N/A
                </template>
                <span class="bspm-text">
                  <div class="BSPMblock"></div>
                  <span class="bestSingleMoney">Best Single Prize Money</span>
                </span>
              </b-col>
              <b-col>
                <b-row>
                  <b-col class="greyText">
                    <div class="TPblock"></div>
                    Tournament Played
                  </b-col>
                  <b-col>
                    <div class="TP" v-if="filterTW.length">
                      {{ filterTW[0].PLAYED }}
                    </div>
                    <div class="TP" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="greyText">
                    <div class="TWblock"></div>
                    Tournaments Wins
                  </b-col>
                  <b-col>
                    <div class="TW" v-if="filterTW.length">
                      {{ filterTW[0].VALUE }}
                    </div>
                    <div class="TW" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="greyText">
                    <div class="TTblock"></div>
                    Top Ten Finishes
                  </b-col>
                  <b-col>
                    <div class="TT" v-if="filterTTF.length">
                      {{ filterTTF[0].VALUE }}
                    </div>
                    <div class="TT" v-else>
                      -
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
          <b-row class="circleStats">
            <b-col cols lg="3" sm=6 >
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#0b3f7e"
                :value="filterGIRP[0].VALUE"
                v-if="filterGIRP.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TWblock"></div>
                    <p>Driving Accuracy</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#00000040"
                :value="filterSSP[0].VALUE"
                v-if="filterSSP.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TTblock"></div>
                    <p>Sand Saves</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#5897E6"
                :value="filterS[0].VALUE"
                v-if="filterS.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TPblock"></div>
                    <p>Scrambling</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#0b3f7e"
                :value="filterGIRP[0].VALUE"
                v-if="filterGIRP.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TWblock"></div>
                    <p>Green In Regulation</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#5897E6"
                :value="filterBB[0].VALUE"
                v-if="filterBB.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TPblock"></div>
                    <p>Bounce Back</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#0b3f7e"
                :value="filterPAPR[0].VALUE"
                v-if="filterPAPR.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TWblock"></div>
                    <p>Putting Average per Round</p>
                  </span>
                </template>
              </Progress>
            </b-col>
            <b-col cols lg="3" sm=6>
              <Progress
                :transitionDuration="2000"
                :radius="65"
                :strokeWidth="10"
                strokeColor="#00000040"
                :value="filterPAPH[0].VALUE"
                v-if="filterPAPH.length"
              >
                <template v-slot:footer>
                  <span class="TitleAndBlock">
                    <div class="TTblock"></div>
                    <p>Putting Average per hole</p>
                  </span>
                </template>
              </Progress>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols lg="4" sm=12 class="mobPaddy">
          <b-row>
            <b-col class="colourOne">
              Driving Distance
            </b-col>
            <b-col class="rightcolourOne">
              <template v-if="filterDD.length">
                {{ filterDD[0].VALUE }}
              </template>
              <template v-else>
                -
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="colourTwo">
              Putting average GIR
            </b-col>
            <b-col class="rightcolourTwo">
              <template v-if="filterBB.length">
                {{ filterBB[0].VALUE }}
              </template>
              <template v-else>
                -
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="colourThree">
              Strokes Average
            </b-col>
            <b-col class="rightcolourThree">
              <template v-if="filterSA.length">
                {{ filterSA[0].VALUE }}
              </template>
              <template v-else>
                -
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="colourFour">
              Average birdies per round
            </b-col>
            <b-col class="rightcolourFour">
              <template v-if="filterABPR.length">
                {{ filterABPR[0].VALUE }}
              </template>
              <template v-else>
                -
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="colourFive">
              Low Round Score
            </b-col>
            <b-col class="rightcolourFive" >
              <template v-if="filterLRS.length">
                {{ filterLRS[0].VALUE }}
              </template>
              <template v-else>
                -
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </templates>

    <!-- All Stats -->
    <div class="greyBlock">
      <b-container>
        <h2 class="latestNews">All Stats</h2>
        <b-row>
          <b-col cols="6">
            <div class="">
              <b-form-select
                v-model="selected"
                :options="options"
                class="mb-3"
                text-field="name"
              ></b-form-select>
            </div>
          </b-col>
          <b-col cols="6" v-if="selected == null">
            <div class="">
              <div class="schedule_block">
                <b-form-select v-model="year" class="schedule-dropdown" v-on:change="changeYear(year)">
                  <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value,
                    index) in tm_params.seasons.seasons_entry
                    .slice()
                    .reverse()"
                    :key="index"
                    :value="value.code"
                  >{{value.desc}}</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="StatsGrey">
      <b-container>
        <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
        <!-- Results -->
        <template v-if="selected == null">
          <PlayerResults
            :playedSeasons="enhancedPlayerInfo.tourns.tourn_details"
            :refno="refno"
            :year="year"
            :id="id"
          />
        </template>
        <template v-else-if="selected == '2'">
          <div v-if="Array.isArray(playerinfo.ORDERS_OF_MERIT.OOM)">
            <PlayerOrderofMerit
              :stats="playerinfo.ORDERS_OF_MERIT.OOM[0].SEASON"
            />
            <!-- One -->
          </div>
          <div v-else>
            <!-- Two -->
            <PlayerOrderofMerit
              :stats="playerinfo.ORDERS_OF_MERIT.OOM.SEASON"
              :config="config"
            />
          </div>
        </template>
        <template v-else-if="selected == '3'">
          <PlayerCareerHighlights
            :stats="playerinfo.CAREER_HIGHLIGHTS.TOURNAMENT"
            :year="year"
          />
        </template>
        
      </b-container>
    </div>


    <!-- News Section -->
    <div class="tournamentNews" v-if="showNews">
      <div class="container">
        <b-row class="courseParaSpace">
          <b-col>
            <h4
              class="hbh"
            >
              Player News
            </h4>
          </b-col>
        </b-row>
        <Post v-if="wptag.length" 
          :tag="wptag[0].id" 
          :count="wptag[0].count" 
        />
      </div>
    </div>

    <!-- Player News -->
    <!-- <div class="Newslatest">
      <b-container>
        <h2 class="latestNews playerNews">Latest on {{ playerinfo.MEMBER.FULL_NAME }}</h2>
        <div>
          <b-row>
            <b-col lg="4" sm="12">
              <div class="newsHeightFix">
                <img class="newsImage" src="https://assets.asiantour.com/asian-tour/2023/11/gag-trophy-scaled.jpg">
              </div>
              <div class="newsTitle">News</div>
              <div class="newsHeading">News One</div>
            </b-col>
            <b-col lg="4" sm="12">
              <div class="newsHeightFix">
                <img class="newsImage" src="https://assets.asiantour.com/asian-tour/2023/11/gag-trophy-scaled.jpg">
              </div>
              <div class="newsTitle">News</div>
              <div class="newsHeading">News Two</div>
            </b-col>
            <b-col lg="4" sm="12">
              <div class="newsHeightFix">
                <img class="newsImage" src="https://assets.asiantour.com/asian-tour/2023/11/gag-trophy-scaled.jpg">
              </div>
              <div class="newsTitle">News</div>
              <div class="newsHeading">News Three</div>
            </b-col>
          </b-row>
        </div>
        
      </b-container>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Progress from "easy-circular-progress";
import PlayerResults from "@/components/playerResults.vue";
import PlayerOrderofMerit from "@/components/playerOrderofMerit.vue";
import PlayerCareerHighlights from "@/components/playerCareerHighlights.vue";
import Post from "@/components/posts.vue";
export default {
  name: "playerprofile",
  components: {
    Progress,
    PlayerResults,
    PlayerOrderofMerit,
    PlayerCareerHighlights,
    Post
  },
  props: ["refno", "Year", "config", "season", "develop"],
  data() {
    return {
      selected:null,
      playerinfo: [],
      enhancedPlayerInfo: [],
      id: [],
      tm_params: [],
      OOMData: [],
      show_name_pronounce: false,
      name_pronounce: "",
      wptag: "",
      options: [
        {
          value : null,
          name: 'Results'
        },
        {
          value : '2',
          name: 'Order of Merit'
        },
        {
          value : '3',
          name: 'Career Highlights'
        }
      ],
      year: this.season,
      playerForname: "",
      playerSurname: "",
      showNews: false
    };
  },
  computed: {
    filterTTF: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("B020")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterBSPM: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("A015")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterTW: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("B010")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterLRS: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("C500")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterSA: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S100")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterABPR: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S116")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterDAP: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S150")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterDD: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S155")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterGIRP: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S160")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterSSP: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S165")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterPAPR: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S170")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterPAPH: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S175")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterPAPG: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S176")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterBB: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S177")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    },
    filterS: function() {
      if (Array.isArray(this.playerinfo.STATISTICS.STATISTIC)) {
        return this.playerinfo.STATISTICS.STATISTIC.filter(
          playerinfo => !playerinfo.CODE.indexOf("S178")
        );
      } else return this.playerinfo.STATISTICS.STATISTIC;
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    gettournPosts() {
      return axios.get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
          "wp/v2/tags?slug=" +
          this.playerForname + '-' + this.playerSurname
        )
      .then(response => {
        this.wptag = response.data;
        if (this.wptag[0].count > 0) {
          this.showNews = true
        }
      });
    },
    namePronounce() {
      console.log("play name sound");
      var audio = new Audio(this.name_pronounce);
      audio.play();
    },
    isMobile() {
      if (screen.width <= 500) {
        return true;
      } else {
        return false;
      }
    },
    // seasonDes(id) {
    //   var year = this.tm_params.seasons.seasons_entry.filter(
    //     seas => seas.code === id
    //   );
    //   return year[0].desc;
    // },
    changeYear: function(year) {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'apga/' +
              year +
              '/' +
              year +
              '-' +
              'players' +
              '-' +
              this.refno +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => {
            this.enhancedPlayerInfo = response.data;
          })
      );
    },
  },
  mounted() {
    axios
      .get(
        "https://api.asia.ocs-software.com/apga/cache/" +
          "apga" +
          "/" +
          "profiles" +
          "/" +
          this.refno +
          "?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        this.playerinfo = response.data;
        this.playerForname = response.data.MEMBER.FORENAME_1;
        this.playerSurname = response.data.MEMBER.SURNAME;
        this.OOMData = response.data.ORDERS_OF_MERIT.OOM;
        this.StatData = response.data.STATISTICS.STATISTIC;
        console.log("this.StatData");
        console.log(this.StatData);
        this.gettournPosts();
        if (response.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION) {
          console.log("player_pronounce start");
          let player_pronounce =
            response.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION;
          if (!Array.isArray(player_pronounce)) {
            player_pronounce = [player_pronounce];
          }
          if (player_pronounce[0].PHOTO_IMAGE.length > 0) {
            this.show_name_pronounce = true;
            this.name_pronounce =
              'https://tms-images.ams3.cdn.digitaloceanspaces.com/apga/' + "/members/" +
              this.refno +
              "/" +
              player_pronounce[0].PHOTO_IMAGE;
          }
        }
        // if (Array.isArray(this.playerInfoOOM)) {
        //   this.playerInfoOOM = response.ORDERS_OF_MERIT.OOM.SEASON.VALUE_TYPE
        // } else {
        // }
        console.log("this.playerinfo");
        console.log(this.playerinfo);
        this.DEFIMAGESAMS3 = response.data.GENERAL.DEFIMAGESAMS3;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ID_ASIAN_TOUR +
            "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
          // process.env.VUE_APP_PLAYAPI_HOST_ENHANCED +
          //   this.refno +
          //   "~season=" +
          //   this.Year +
          //   "~enhanced=y~jsout=x~xsl=~?randomadd=" +
          //   new Date().getTime()
        );
      })
      .then(response => {
        (this.tm_params = response.data),
          (this.id = response.data.tm_params.season_code);
        return axios.get(
          "https://api.asia.ocs-software.com/apga/cache/" +
            this.develop +
            "/" +
            this.id +
            "/" +
            this.id +
            "-players-" +
            this.refno +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.enhancedPlayerInfo = response.data;
      });
  }
  // methods: {
  //   seasonDes(id){
  //     var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
  //     return year[0].desc
  //   },
  //   changeYear: function(event, season_code) {
  //     var id;
  //     if (season_code && season_code.length > 0) {
  //       id = season_code
  //     } else {
  //       id = event.target.getAttribute("title");
  //     }
  //     return (
  //       (this.id = id),
  //       axios
  //         .get(
  //           'https://api.asia.ocs-software.com/apga/cache/' + this.develop + '/' + this.id + '/' + this.id + '-players-' + this.refno + '.json?randomadd=' +
  //           new Date().getTime()
  //         )
  //         .then(response => {
  //           this.enhancedPlayerInfo = response.data;
  //         })
  //     );
  //   },
  //   players: function() {
  //   var d = new Date();
  //   var pastYear = d.getFullYear() - 1;
  //   console.log("pastYear")
  //   console.log(pastYear)
  //   axios
  //     .get(
  //       'https://api.asia.ocs-software.com/apga/cache/' + this.develop + '/' + pastYear + '/' + pastYear + '-players-' + this.refno + '.json?randomadd=' +
  //           new Date().getTime()
  //     )
  //     .then(response => {
  //       if (typeof response.data.ooms.tourn_details == 'undefined') {
  //         this.changeYear(null, "2022")
  //         // return
  //       }
  //       this.enhancedPlayerInfo = response.data;
  //     })
  //   }
  // },
  // mounted() {
  //   axios
  //     .get(
  //         'https://api.asia.ocs-software.com/apga/cache/' + 'apga' + '/' + 'profiles' + "/" + this.refno + '?randomadd=' +
  //           new Date().getTime()
  //     )
  //     .then(response => {
  //       this.playerinfo = response.data;
  //       this.DEFIMAGESAMS3 = response.data.GENERAL.DEFIMAGESAMS3;
  //       return axios.get(
  //         process.env.VUE_APP_TIC_BASE + process.env.VUE_APP_ID_ASIAN_TOUR + "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
  //         new Date().getTime()
  //         // process.env.VUE_APP_PLAYAPI_HOST_ENHANCED +
  //         //   this.refno +
  //         //   "~season=" +
  //         //   this.Year +
  //         //   "~enhanced=y~jsout=x~xsl=~?randomadd=" +
  //         //   new Date().getTime()
  //       );
  //     })
  //     .then(response => {
  //       this.tm_params = response.data
  //       this.id = response.data.tm_params.season_code
  //       return axios.get(
  //         'https://api.asia.ocs-software.com/apga/cache/' + this.develop + '/' + this.id + '/' + this.id + '-players-' + this.refno + '.json?randomadd=' +
  //           new Date().getTime()
  //       );
  //     })
  //     .then(response => {
  //       this.players(this.id);
  //       if (typeof response.data.ooms.tourn_details == 'undefined') {
  //         this.changeYear(null, "2022")
  //         // return
  //       }
  //       this.enhancedPlayerInfo = response.data;
  //     });
  // }
};
</script>

<style scoped>
::v-deep .hrLines {
  margin-top: 20px;
  margin-bottom: 20px;
  display: none;
}
::v-deep .equalPad {
  /* padding-left: 0; */
  padding-right: 10px;
}
.hbh {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 30px;
}
::v-deep .extraPad {
  padding-top: 60px;
  /* margin-top: 30px; */
}
.StatsGrey {
  background-color: #F5F5F5;
  padding-bottom: 4%;
  padding-top: 0%;
}
.profileImageOuter {
  max-height: 400px;
  overflow: hidden;
  width: 75%;
  border-radius: 13rem;
  float: right;
  border: 15px solid #dadada;
}
.profileImage {
  max-height: 370px;
  overflow: hidden;
  border-radius: 13rem;
  border: 15px solid #7E7E7E;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.socialLine {
  margin-top: 10px;
}
::v-deep .vue-circular-progress .circle {
  color: #042F62;
}
span.TitleAndBlock {
  display: -webkit-inline-box;
  color: #707070;
  font-size: 12px;
  line-height: 1.8;
}
span.bestSingleMoney {
  position: relative;
  top: -5px;
}
.BSPMblock {
  width: 5px;
  height: 20px;
  background-color: #0A3F7F;
  margin-right: 10px;
  display: inline-flex;
}
.TPblock {
  width: 5px;
  height: 20px;
  background-color: #5897E6;
  margin-right: 10px;
}
.TWblock {
  width: 5px;
  height: 20px;
  background-color: #0A3F7F;
  margin-right: 10px;
}
.TTblock {
  width: 5px;
  height: 20px;
  background-color: #A8B7C9;
  margin-right: 10px;
}
.bspm-text {
  color: #707070;
  font-size: 12px;
  font-weight: 400;
}
.bigPrizeMoney {
  color: #0A3F7F;
  font-size: 40px;
  font-weight: 500;
  display: grid;
}
.circleStats {
  padding-top: 50px;
  padding-bottom: 50px;
}
.TP {
  color: #5897E6;
  font-size: 24px;
  font-weight: 500;
  float: right;
  position: relative;
  top: -7px;
  right: 10px;
}
.TW {
  color: #0A3F7F;
  font-size: 24px;
  font-weight: 500;
  float: right;
  position: relative;
  top: -7px;
  right: 10px;
}
.TT {
  color: #A8B7C9;
  font-size: 24px;
  font-weight: 500;
  float: right;
  position: relative;
  top: -7px;
  right: 10px;
}
.greyText {
  color: #707070;
  font-size: 12px;
  display: inline-flex;
}
.row.container.MiddleStat {
  margin: auto;
  padding-left: 0;
  padding-right: 0;
}
.colourOne {
  font-size: 11px;
  color: #fff;
  background-color: #5897E6;
  height: 37px;
  margin-bottom: 10px;
  line-height: 3.4;
  border-radius: 0px 27px 27px 0px;
}
.colourTwo {
  font-size: 11px;
  color: #fff;
  background-color: #0A3F7F;
  height: 37px;
  margin-bottom: 10px;
  line-height: 3.4;
  border-radius: 0px 27px 27px 0px;
}
.colourThree {
  font-size: 11px;
  color: #fff;
  background-color: #A8B7C9;
  height: 37px;
  margin-bottom: 10px;
  line-height: 3.4;
  border-radius: 0px 27px 27px 0px;
}
.colourFour {
  font-size: 11px;
  color: #fff;
  background-color: #C3C3C3;
  height: 37px;
  margin-bottom: 10px;
  line-height: 3.4;
  border-radius: 0px 27px 27px 0px;
}
.colourFive {
  font-size: 11px;
  color: #fff;
  background-color: #042F62;
  height: 37px;
  margin-bottom: 10px;
  line-height: 3.4;
  border-radius: 0px 27px 27px 0px;
}
.rightcolourOne {
  font-size: 24px;
  color: #5897E6;
  font-weight: 500;
  text-align: right;
}
.rightcolourTwo {
  font-size: 24px;
  color: #0A3F7F;
  font-weight: 500;
  text-align: right;
}
.rightcolourThree {
  font-size: 24px;
  color: #A8B7C9;
  font-weight: 500;
  text-align: right;
}
.rightcolourFour {
  font-size: 24px;
  color: #C3C3C3;
  font-weight: 500;
  text-align: right;
}
.rightcolourFive {
  font-size: 24px;
  color: #042F62;
  font-weight: 500;
  text-align: right;
}
.playerNews {
  margin-top: 30px;
}
::v-deep .custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff !important;
  color: #7E7E7E !important;
  border: 0px solid #c7c7cc !important;
  margin-top: 15px;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  height: 43px;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center/12px 8px no-repeat!important;
}
.dropdownBlock {
  width: 100%;
}
.greyBlock {
  background-color: #F5F5F5;
  padding-top: 60px;
  padding-bottom: 60px;
}
.newsHeightFix {
  height: 200px;
  overflow: hidden;
}
.newsImage {
  width: 100%;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 19px;
  font-weight: 500;
}
.Newslatest {
  padding-top: 0px;
  padding-bottom: 60px
}
.latestNews {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
button.btn.namePronounce.btn-secondary {
  background-color: #fff;
  padding: 0.275rem 0.75rem;
}
::v-deep .dropdown-item {
  color: #212529 !important;
}
::v-deep .btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #133f7b !important;
  border-color: #133f7b !important;
  box-shadow: 0 0 0 0rem rgba(58, 176, 195, 0.5) !important;
}
img.fbIcon {
  width: 25px;
  margin-top: -4px;
}
p.mobileNatText {
  margin-left: 10px;
  margin-bottom: 0px;
}
.natBlockmobile {
  display: inline-flex;
}
::v-deep span.NoData {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  display: block;
}
::v-deep div#accordion-2 {
  display: block !important;
}
::v-deep
  .vue-circular-progress.puttingAverPerHole
  > div.circle
  > div.percent
  > span.percent_sign {
  display: none !important;
}
::v-deep .table thead th {
  border-bottom: 0px solid #dee2e6 !important;
}
::v-deep .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6 !important;
}
::v-deep .table {
  width: 100%;
  margin-bottom: 0rem;
  color: #212529;
}
::v-deep .b-table-sticky-header,
.table-responsive,
[class*="table-responsive-"] {
  margin-bottom: 0rem;
}
::v-deep .card {
  border: 0px !important;
}
::v-deep tr:nth-child(even) {
  background-color: #F5F5F5;
}
::v-deep .table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #dee2e6;
}
button.btn.results.btn-info.btn-block.not-collapsed {
  padding-left: 5px;
}
button.btn.results.btn-info.btn-block.collapsed {
  padding-left: 5px;
}
button.btn.btn-info.btn-block.collapsed {
  padding-left: 5px;
}
button.btn.btn-info.btn-block.not-collapsed {
  padding-left: 5px;
}
::v-deep .card {
  border-radius: 0px !important;
}
button.btn.results.btn-info.btn-block.not-collapsed::after {
  display: none;
}
button.btn.results.btn-info.btn-block.collapsed::after {
  display: none;
}
::v-deep ul.dropdown-menu.show {
  position: absolute;
  transform: translate3d(-83px, 38px, 2px) !important;
  overflow: scroll;
  height: 200px;
  /* margin-top: 15px; */
}
.DropdownYearBlock {
  margin-right: 8px;
  float: right;
  margin-top: -3px;
}
.pipe-greytwo {
  background: #e0dfdf;
  height: 1px;
  position: relative;
  top: -18px;
}
.pipe-grey {
  background: #e0dfdf;
  height: 1px;
  position: relative;
  top: -17px;
}
::v-deep thead {
  color: white;
  background-color: #A8B7C9;
  font-size: 12px;
}
::v-deep tbody {
  font-size: 14px;
  color: #7E7E7E;
}
.card-body {
  padding: 0px;
}
::v-deep .btn-secondary {
  background: #fff !important;
  color: #aaa !important;
  border: 0px solid #c7c7cc !important;
  margin-top: 15px;
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  height: 43px;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right!important;
  margin-top: 12px;
}
p.filterStat {
  color: #133f7b;
  font-weight: 500;
  margin-top: 4px;
}
p.middleStatsSec {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.vue-circular-progress {
  display: inline-block;
  text-align: center;
}
.vue-circular-progress > p {
  color: #707070;
  font-size: 12px;
}
::v-deep .vue-circular-progress > .circle > .percent > .percent__dec {
  font-size: 28px;
  color: #042F62;
}

.infoTopSec {
  color: #0A3F7F !important;
  font-size: 16px;
  text-align: right;
  font-weight: 500;
  float: left;
  padding-right: 8px;
}
.mainImg {
  /* max-width: 100%;
  max-height: 320px; */
}
.baseInfo {
  /* background-image: url("https://assets.asiantour.com/asian-tour/2020/05/AsianTour_Website2020_Home_PlaceHolder_Image_02-scaled.jpg"); */
  background-color: #A8B7C9;
  background-image: linear-gradient(to right, #F5F5F5 80% , #5897E6);
}
.baseInfoMob {
  /* background-image: url("https://assets.asiantour.com/asian-tour/2020/05/AsianTour_Website2020_Home_PlaceHolder_Image_02-scaled.jpg"); */
  background-color: #A8B7C9;
  background-image: linear-gradient(#5897E6 30% , #F5F5F5);
}
.basicInfo {
  color: #0A3F7F;
  font-weight: 300;
  font-size: 16px;
}
.flag {
  max-height: 20px;
  border: 0.25px solid #e2e1e1
}
.sponsor {
  height: 15px;
  padding-left: 25px;
  margin-top: 3px;
}
.splashInfo {
  /* padding-top: 5%; */
  /* padding-bottom: 10%; */
}
#name {
  font-size: 24px;
  color: #042F62;
  font-weight: 500;
  padding-bottom: 0px;
  padding-top: 80px;
}
.fa-facebook-square {
  color: #3b5998 !important;
  font-size: 20px !important;
}
.fa-twitter {
  color: #6697e0 !important;
  font-size: 20px !important;
}
.fa-instagram {
  /* color: #fff; */
  font-size: 20px !important;
}
::v-deep li {
  list-style-type: none;
}
.topStats {
  padding-top: 5%;
}
.bioGrey {
  /* background-color: #ebebeb; */
  padding-bottom: 4%;
  padding-top: 4%;
}
.bioTitle {
  color: #0A3F7F;
  padding-top: 4%;
  font-size: 20px;
  padding-bottom: 2%;
}
.pickYear {
  top: 8%;
}
.bio {
  color: #707070;
  font-size: 14px;
}
.statNumber {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  color: #042F62;
}
.visual {
  /* font-size: 12pt; */
  font-size: 14px;
  padding-top: 5%;
}
#visStats {
  /* background-color: #f6f6f6; */
}
.midC {
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;
}
.lightB {
  color: #6697e0;
}

.darkB {
  color: #0b3f7e;
}
::v-deep .remove > .vue-circular-progress > .circle > .percent > .dot {
  display: none;
}
::v-deep .remove > .vue-circular-progress > .circle > .percent > .percent__dec {
  display: none;
}
::v-deep
  .removePercent
  > .vue-circular-progress
  > .circle
  > .percent
  > .percent_sign {
  display: none;
}
.black {
  color: black;
}
.backDB {
  background-color: #0b3f7e;
  color: white;
  height: 70px;
}

.backLB {
  background-color: #6697e0;
  color: white;
  height: 70px;
}
::v-deep .backDB > .vue-circular-progress {
  padding-top: 15%;
  display: block;
}
::v-deep .backLB > .vue-circular-progress {
  padding-top: 15%;
  display: block;
}
.frontDB {
  background: linear-gradient(
    67.5deg,
    rgba(11, 63, 126, 0.9) 9%,
    rgba(11, 63, 126, 0.9) 92%,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 0.9) 100%
  );
  padding: 20px;
  color: white;
}
.reverseDB {
  background: linear-gradient(
    -113.5deg,
    rgba(255, 255, 255, 0.9) -54%,
    rgba(255, 255, 255, 0.9) 100%,
    rgba(255, 255, 255, 0.9) -24%,
    rgba(255, 0, 0, 0.9) -12%
  );
  padding: 11px;
}

::v-deep .reverseDB > .vue-circular-progress > .circle .percent {
  top: 120%;
}

.frontLB {
  background: linear-gradient(
    67.5deg,
    rgba(102, 151, 224, 0.9) 9%,
    rgba(102, 151, 224, 0.9) 92%,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 0.9) 100%
  );
  padding: 20px;
  color: white;
}
.reverseLB {
  background: linear-gradient(
    -113.5deg,
    rgba(255, 255, 255, 0.9) -54%,
    rgba(255, 255, 255, 0.9) 100%,
    rgba(255, 255, 255, 0.9) -24%,
    rgba(255, 0, 0, 0.9) -12%
  );
  padding: 11px;
}

::v-deep .reverseLB > .vue-circular-progress > .circle .percent {
  top: 120%;
}
.sPad {
  padding-left: 5%;
  padding-right: 5%;
}
.mainRS {
  padding-bottom: 6%;
}
.bottomStats {
  height: 55%;
}
.underline {
  border-bottom: 1px solid #e0dfdf;
  padding-top: 5%;
}
.sideline {
  border-right: 1px solid #e0dfdf;
}
.matchOne {
  height: 110px;
}
.matchTwo {
  height: 55px;
}
.btn-info {
  background-color: #133f7b;
  border-color: #133f7b;
  text-align: left;
}
.btn-info::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.card-header {
  background-color: #133f7b;
}
.statsButton {
  background-color: white;
  border-radius: 10px;
  height: 75px;
  margin-top: -111px;
}
.statsPad {
  padding-bottom: 5%;
}
@media only screen and (max-width: 990px) {
  .DropdownYearBlock[data-v-5b6213fe] {
    margin-right: 8px;
    float: none;
    margin-top: -3px;
    margin-left: 12px;
  }
  .statsButton {
    margin-top: -76px;
  }
  p.filterStat {
    text-align: center;
  }
  .pickYear {
    text-align: center;
    top: 0;
  }
}
@media only screen and (max-width: 768px) {
  .statsButton {
    height: 104px;
    width: 730px;
    margin-top: 0px;
  }
  .noPadColRight {
    padding-right: 0px;
    padding-left: 5px;
  }
  .noPadColLeft {
    padding-left: 0px;
  }
  .midC {
    padding-bottom: 2%;
  }
  .backDB {
    height: 50px;
  }
  .backLB {
    height: 50px;
  }
}
@media only screen and (max-width: 500px) {
  .newsHeading {
    color: #0A3F7F;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .mobMiddleStats {
    margin-top: 50px;
  }
  .vue-circular-progress {
    text-align: left;
  }
  .mobPaddy.col-sm-12.col-lg-4 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .splashInfo {
    background-color: #F5F5F5;
  }
  .col-sm-6.col-lg-3 {
    width: 50%;
  }
  .profileImageOuter {
    max-height: 400px;
    overflow: hidden;
    width: 100%;
    border-radius: 13rem;
    float: right;
    border: 15px solid #dadada;
  }
  ::v-deep .mobileMid-Circle {
    text-align: center;
  }
  .mobileMid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .midC {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }
  ::v-deep .natBlockmobile {
    display: inline-flex;
    padding-bottom: 15px;
    float: right;
    margin-right: 14px;
  }
  ::v-deep .mobileFloat {
    float: right;
    margin-top: 17px;
    padding-right: 5px;
  }
  ::v-deep #name {
    font-size: 20pt;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  ::v-deep .vue-circular-progress .percent__int {
    font-size: 18px;
  }
  .removePercent.noPadColLeft.darkB.col-md-4.col-3 {
    padding-left: 13px;
  }
  ::v-deep .percent {
    color: #042F62!important;
  }
  ::v-deep .vue-circular-progress .percent {
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    font-weight: 700;
    text-align: center;
    line-height: 1;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #042F62!important;
    font-size: 24px;
  }
  .infoTopSec {
    color: #0A3F7F !important;
    font-size: 16px;
    text-align: right;
    font-weight: 500;
    float: left;
    padding-right: 8px;
  }
  .statNumber {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  ::v-deep .vue-circular-progress > .circle > .percent > .percent__dec {
    font-size: 18px;
  }
  ::v-deep .noPadColLeft {
    /* padding-left: 50px; */
    padding-left: 82px;
  }
}
@media only screen and (max-width: 480px) {
  .pipe-grey {
    background: #e0dfdf;
    height: 1px;
    position: relative;
    top: -6px;
  }
  .pipe-greytwo {
    background: #e0dfdf;
    height: 1px;
    position: relative;
    top: -8px;
  }
  .mobHide {
    display: none;
  }
  .midC {
    padding-top: 3%;
  }
  .sPad {
    padding-bottom: 4%;
  }
  .midC > .row > .col > p {
    font-size: 10pt;
  }
  .frontDB {
    background: linear-gradient(
      75.5deg,
      rgba(11, 63, 126, 0.9) 9%,
      rgba(11, 63, 126, 0.9) 89%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0.9) 100%
    );
  }
  .frontLB {
    background: linear-gradient(
      75.5deg,
      rgba(102, 151, 224, 0.9) 9%,
      rgba(102, 151, 224, 0.9) 92%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0.9) 100%
    );
  }
  .bottomStats {
    font-size: 11pt;
    padding-bottom: 3%;
  }
  .statsButton {
    height: 104px;
    width: 350px;
    margin-top: 0px;
    margin-left: 25px;
  }
  .bio {
    font-size: 10pt;
  }
  .backDB > .vue-circular-progress {
    padding-top: 10%;
  }
  .backLB > .vue-circular-progress {
    padding-top: 10%;
  }
  .cent {
    text-align: right;
  }
  .lowScoreMob {
    /* padding-left: 60px; */
  }
  .strokeMob {
    /* padding-left: 25px; */
  }
}
@media only screen and (max-width: 375px) {
  .profileImage {
    height: 309px;
  }
  .profileImageOuter {
    max-height: 340px;
    overflow: hidden;
    width: 100%;
    border-radius: 13rem;
    float: right;
    border: 15px solid #dadada;
  }
  ::v-deep .noPadColLeft {
    /* padding-left: 50px; */
    padding-left: 57px;
  }
  .frontLB {
    background: linear-gradient(
      75.5deg,
      rgba(102, 151, 224, 0.9) 9%,
      rgba(102, 151, 224, 0.9) 89%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 0.9) 100%
    );
  }
}
</style>